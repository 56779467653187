import React from "react";

import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Button,
} from "@material-ui/core";
import moment from "moment";
import Animation from "../Animation";
import successAnimation from "../../assets/animation/success.json";
function PaymentSuccessful({ locale, setActiveStep }) {
  return (
    <Grid container justify="center" spacing={2} alignItems="center">
      <Grid
        container
        item
        xs={12}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Grid item xs={12}>
          <Animation animationData={successAnimation} />
        </Grid>
        <Grid item>
          <Typography variant="h3">{locale.paymentSuccess}</Typography>
        </Grid>
      </Grid>

      <Grid
        container
        item
        spacing={2}
        xs={12}
        justify="center"
        alignItems="center"
      >
        <Grid item md={6} xs={12}>
          <Button color="primary" fullWidth onClick={() => window.print()}>
            <i
              className="fa fa-file-download"
              style={{ marginRight: "0.5rem" }}
            />
            {locale.receipt}
          </Button>
        </Grid>
        <Grid item md={6} xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => setActiveStep((prev) => prev + 1)}
          >
            Continue to the form
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PaymentSuccessful;
