import {
  Backdrop,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core"
import loadingAnimation from "./../../assets/animation/loading.json"
import hindi from "./../../lang/hi.json"
import english from "./../../lang/en.json"
import gujarati from "./../../lang/gu.json"
import mixpanel from "mixpanel-browser"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import customAxios from "../../axios"
import logo from "./../../assets/images/logos/Group 36999.png"
import Glogo from "./../../assets/images/logos/WhatsApp Image 2024-03-02 at 9.44.13 PM.jpeg"
import Animation from "../../components/Animation"
import PaymentDetails from "../PaymentPage"
import PaymentPage from "../PaymentPage"
import Assessment from "../Assessment"
import Submitted from "../Submitted"
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}))
function Form({ isMobile }) {
  let params = window.location.pathname
  const classes = useStyles()
  params = params.substring(1)

  const [locale, setLocale] = useState("en")

  const [state, setState] = useState({})
  const [activeStep, setActiveStep] = useState(-1)

  const [loading, setLoading] = useState(true)
  const [brandLogo, setBrandLogo] = useState(null)
  const locales = {
    hi: hindi,
    en: english,
    guj: gujarati,
  }

  const localesMap = [
    { label: "हिंदी", value: "hi" },
    { value: "en", label: "English" },
    { value: "guj", label: "ગુજરાતી" },
  ]
  useEffect(() => {
    if (params){
      if(params === "1e607308-0e34-4e0f-ace7-e1c5c397b0a1"){
        window.location.href = "https://docs.google.com/forms/d/1m4esf9ZnfJ3-jpqnOh22FwgLAC8A6tKHTtsV_1snOfI/viewform?edit_requested=true#?fbclid=PAAaaa6H-85sSo_2DtTfdgV1IUhWVaUmOamX_QUwhu1IHxGDEUbNnZIyCu7SU_aem_AfxVNqyTbg0fhvL_2Gz1kKG4bON1-aoFttyOeteBYkbygUD1DhNRuzWfhjBWf2vxpuA"
      } else {
        customAxios
          .get(`/ai/fetch-bin?uuid=${params}`)
          .then((res) => {
            if (res.data.data) {
              mixpanel.init("69acf8b6bfff11df33f40058daa07536", {
                ignore_dnt: true,
                cookie_expiration: 1,
              })
    
              const temp = res?.data?.data
    
              setState({ ...res.data.data })
              if (res.data.data.paid) {
                setActiveStep(1)
              } else {
                setActiveStep(res.data.data.step)
              }
              mixpanel.register({ docId: temp?.docId })
              if (temp?.brandLogo) setBrandLogo(temp?.brandLogo)
              if (temp?.patientId) {
                mixpanel.identify(temp.patientId)
                mixpanel.track("identify", { userId: temp.patientId })
              } else {
                mixpanel.identify(temp.phoneNo)
                mixpanel.track("identify", { userId: temp.phoneNo })
              }
            } else {
              setActiveStep(-1)
            }
            setLoading(false)
          })
          .catch((err) => {
            setLoading(false)
            toast.error("Please verify the link once.")
          })
      }
    }
    
    //eslint-disable-next-line
  }, [params])
  const getActiveStep = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <PaymentPage
            setState={setState}
            state={state}
            setLoading={setLoading}
            locale={locales[locale]}
            setActiveStep={setActiveStep}
            paymentLocale={locale}
          ></PaymentPage>
        )
      case 1:
        return (
          <Assessment
            setLoading={setLoading}
            fullData={state}
            setActiveStep={setActiveStep}
            locale={locales[locale]}
          />
        )
      case 2:
        return <Submitted locale={locale} />

      default:
        ;<Typography>
          Some error occured. Please verify the link once
        </Typography>
    }
  }

  return (
    <Box mx={isMobile ? 1 : 1} my={isMobile ? 3 : 2}>
      <Backdrop className={classes.backdrop} open={loading}>
        <Animation animationData={loadingAnimation} />
      </Backdrop>
      <Paper elevation={isMobile ? 2 : 0} style={{ borderRadius: "15px" }}>
        <Grid
          container
          justify="flex-start"
          spacing={2}
          style={{ padding: "2rem" }}
        >
          <Grid
            item
            style={{ textAlign: "center" }}
            container
            spacing={1}
            justify="center"
            xs={12}
          >
            {brandLogo ? (
              <img
                src={brandLogo}
                alt="Logo"
                style={{
                  objectFit: "contain",
                  width: "300px",
                }}
              ></img>
            ) : (
              <React.Fragment>
                <img
                  src={Glogo}
                  alt="Logo"
                  // onClick={() =>
                  //   window.open("https://paperplanetech.co", "_blank")
                  // }
                  style={{
                    width: isMobile ? "60%" : "12%",
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                ></img>
                
              </React.Fragment>
            )}

            {brandLogo ? (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "12px",
                    color: "#404241",
                  }}
                >
                  Powered By{" "}
                </span>
                <img
                  src={logo}
                  alt="Logo"
                  // onClick={() =>
                  //   // window.open("https://paperplanetech.co", "_blank")
                  // }
                  style={{
                    width: "150px",
                    marginLeft: "0.4rem",
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                ></img>
              </Grid>
            ) : null}

                <Grid item xs={12} style={{marginTop: isMobile ? "-5%" : "-1%"}}>
                  <Typography style={{textAlign: "center", fontWeight: "600", fontSize: "20px"}}>
                    Gxza Health
                  </Typography>
                </Grid>

                <Grid
                item
                xs={12}
                style={{
                  // display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    fontWeight: "600",
                    fontSize: "12px",
                    color: "#404241",
                    marginBottom: "6px"
                  }}
                >
                  Powered By{" "}
                </div>
                <img
                  src={logo}
                  alt="Logo"
                  // onClick={() =>
                  //   // window.open("https://paperplanetech.co", "_blank")
                  // }
                  style={{
                    width: isMobile ? "30%" : "8%",
                    // marginLeft: "0.4rem",
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                ></img>
              </Grid>
          </Grid>
          {/* <Grid container xs={12} item justify="center">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={locale}
              row
              color="primary"
              onChange={(e) => {
                mixpanel.track("Language changed", { lang: e.target.value })
                setLocale(e.target.value)
              }}
            >
              {localesMap.map((ele) => (
                <FormControlLabel
                  value={ele.value}
                  control={<Radio color="primary" />}
                  label={ele.label}
                />
              ))}
            </RadioGroup>
          </Grid> */}
          <Grid item xs={12}>
            {/* <Stepper alternativeLabel activeStep={activeStep}>
              {locales[locale].steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
             */}
          </Grid>

          <Grid item xs={12} container justifyContent="center">
            {getActiveStep(activeStep)}
            {/* {activeForm(activeStep)} */}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default Form
