import "./App.css";
import Form from "./views/Form";
import { ToastContainer } from "react-toastify";
import { withWidth } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";

function App({ width }) {
  const isMobile = width === "xs" || width === "sm" ? true : false;

  return (
    <div>
      <Form isMobile={isMobile} />
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        limit={1}
        hideProgressBar={true}
      />
    </div>
  );
}

export default withWidth()(App);
