import { createTheme } from "@material-ui/core";

let theme = createTheme({
  palette: {
    primary: {
      main: "#6C7BFF",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#585757",
      contrastText: "#14388A",
    },
  },
  typography: {
    fontFamily: "Inter",
    h1: {
      fontSize: "25px",
      lineHeight: "54px",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "22px",
      lineHeight: "48px",
      fontWeight: "normal",
    },
    h3: {
      fontSize: "20px",
      lineHeight: "43px",
      fontWeight: "300",
    },
    h4: {
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "38px",
    },
    h5: {
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "32px",
    },
    h6: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "28px",
    },

    subtitle1: {
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "15px",
      letterSpacing: "0em",
    },
  },

  overrides: {
    MuiInput: {
      input: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          display: "none",
        },
      },
    },

    MuiButton: {
      disableElevation: true,
      label: {
        fontWeight: "500",
        textTransform: "none",
      },
      sizeSmall: {
        fontSize: "14px",
      },
      contained: {
        fontSize: "20px",
        boxShadow:
          "0px 0px 0px -0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
      },
      root: {
        fontFamily: "Inter",
        fontSize: "20px",
        lineHeight: "30px",
        boxShadow:
          "0px 0px 0px -0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%)",
        borderRadius: "30px",
      },
    },

    MuiInput: {
      input: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          display: "none",
        },
      },
    },

    MuiIconButton: {
      root: {
        padding: "5px",
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: "30px",
      },
    },
  },
});
theme.overrides = {
  ...theme.overrides,
  MuiButton: {
    ...theme.overrides.MuiButton,
    contained: {
      ...theme.overrides.MuiButton.contained,
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
      },
    },
  },
};
theme.typography = {
  ...theme.typography,
  h1: {
    ...theme.typography.h1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      lineHeight: "35px",
    },
  },
  h2: {
    ...theme.typography.h2,
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
      lineHeight: "27px",
    },
  },
  h3: {
    ...theme.typography.h3,
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "27px",
    },
  },
  h4: {
    ...theme.typography.h3,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "27px",
    },
  },
  h5: {
    ...theme.typography.h5,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "27px",
    },
  },
  h6: {
    ...theme.typography.h6,
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "25px",
    },
  },
};
export default theme;
