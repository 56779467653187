import React from "react";
import { Button, Divider, Grid, Typography } from "@material-ui/core";
import Animation from "../Animation";
import errorAnimation from "./../../assets/animation/error.json";
function PaymentError() {
  return (
    <Grid container justify="center" spacing={2} alignItems="center">
      <Grid
        container
        item
        xs={12}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Grid item xs={12}>
          <Animation animationData={errorAnimation} />
        </Grid>
        <Grid item>
          <Typography variant="h3">Payment Unsuccessful</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h5">
          Some error occured while processing your payment. Please refresh the
          page.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item>
        <Button
          color="primary"
          onClick={() => window.location.reload()}
          variant="contained"
        >
          Refresh
        </Button>
      </Grid>
    </Grid>
  );
}

export default PaymentError;
