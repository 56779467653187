import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TextField,
  Chip,
} from "@material-ui/core";
import { toast } from "react-toastify";
import moment from "moment";
import customAxios from "../../axios";

function PaymentDetails({ state, handlePayment, locale, setLoading }) {
  const [couponDetails, setCouponDetails] = useState({});
  const [coupon, setCoupon] = useState("");
  const [fees, setFees] = useState(state?.amount);
  useEffect(() => {
    if (state) setFees(state?.amount);
  }, [state]);
  const style = {
    labelCell: {
      fontSize: "17px",
      color: "#99A6C4",
    },
    valueCell: {
      fontSize: "17px",
    },
  };
  const handleClick = () => {
    if (coupon) {
      setLoading(true);
      customAxios
        .get(`/doctor/get-coupon-detail/${state?.docId}?code=${coupon}`)
        .then((res) => {
          setLoading(false);
          if (res.data.success) {
            console.log("res", res.data.data);
            setCouponDetails({ ...res.data.data });
            let temp = fees;
            temp = temp - (res.data.data.discount.value / 100) * temp;
            setFees(temp);
            toast.success("Yayiee. Coupon Applied!");
          } else {
            toast.error("Coupon code not found.");
          }
        })
        .catch((err) => {
          setLoading(false);

          toast.error("Some error occured. Please try again later");
        });
    } else {
      setLoading(false);

      toast.error("Please enter a coupon code");
    }
  };

  return (
    <Grid
      container
      spacing={4}
      item
      xs={12}
      md={8}
      alignItems="center"
      style={{ marginTop: "10px" }}
    >
      <Grid item xs={12}>
        <TextField
          value={coupon}
          variant="outlined"
          onChange={(e) => setCoupon(e.target.value.toUpperCase())}
          label="Coupon Code"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleClick();
            }
          }}
          InputLabelProps={{ shrink: true }}
          // FormHelperTextProps={{
          // 	className: classes.helperText
          // }}
          helperText={
            couponDetails?.discount
              ? `Yayy! ${couponDetails?.discount?.value}% discount will be applied on checkout.`
              : "Who doesn't love discounts?"
          }
          placeholder="eg. YAY2021"
          InputProps={{
            readOnly: couponDetails?.discount ? true : false,
            endAdornment: !couponDetails?.discount ? (
              <Button color="primary" onClick={() => handleClick()}>
                Apply
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={() => {
                  setCouponDetails({});
                  setFees(state?.amount);
                  setCoupon("");
                }}
              >
                Change
              </Button>
            ),
          }}
          fullWidth
        ></TextField>
      </Grid>

      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={style.labelCell}>{locale.date}</TableCell>
                <TableCell style={style.valueCell}>
                  {moment.utc(state?.appointmentDate).format("DD MMMM YYYY ")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={style.labelCell}>{locale.time}</TableCell>
                <TableCell style={style.valueCell}>
                  {" "}
                  {state?.appointmentTime}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={style.labelCell}>{locale.mode}</TableCell>
                <TableCell style={style.valueCell}>
                  {
                    locale?.modes?.find(
                      (ele) => ele?.value.toLowerCase() === state?.mode
                    )?.label
                  }
                  {state.type === "emergency" ? (
                    <Chip
                      label="Emergency"
                      style={{
                        backgroundColor: "#FF3E43",
                        color: "white",
                        borderRadius: "0.25em",
                        marginLeft: "1rem",
                      }}
                    ></Chip>
                  ) : null}
                </TableCell>
              </TableRow>

              <TableRow
                style={{ display: state?.mode === "offline" ? "" : "none" }}
              >
                <TableCell style={style.labelCell}>{locale.location}</TableCell>
                <TableCell style={style.valueCell}>
                  {" "}
                  {state?.orgName}, {state?.address}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={style.labelCell}>{locale.fee}</TableCell>
                <TableCell style={style.valueCell}> Rs. {fees}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid
        container
        spacing={2}
        item
        justify="center"
        direction="column"
        alignItems="center"
        xs={12}
        onClick={() => {
          handlePayment(couponDetails);
        }}
      >
        <Button variant="contained" color="primary">
          {locale.payOnline}
        </Button>
      </Grid>
    </Grid>
  );
}

export default PaymentDetails;
