import axios from "axios";
// import { config } from '../views/helper';
// import { store } from "../redux/index";
// import { logout } from "../redux/actions/auth.actions";
// const axios = require('axios');

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.
const PRODUCTION_BASE_URL =
  "https://pplane-api-prod.paperplane.health/api/v1/";
// const DEVELOPMENT_BASE_URL = 'https://doctor-in-paperplane.herokuapp.com/api/v1/';

const customAxios = axios.create({
  baseURL: PRODUCTION_BASE_URL,
});

// Step-2: Create request, response & error handlers

// // Step-3: Configure/make use of request & response interceptors from Axios
// // Note: You can create one method say configureInterceptors, add below in that,
// // export and call it in an init function of the application/page.

export default customAxios;
