import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import customAxios from "./../../axios/index";
import { toast } from "react-toastify";
import {
  defaultOptions,
  displayRazorpay,
  loadRazorPay,
} from "../../razorPayHelper";
import PaymentError from "../../components/PaymentError";
import PaymentDetails from "../../components/PaymentDetails";
import PaymentSuccessful from "../../components/PaymentSuccessful";
function PaymentPage({
  state,
  paymentLocale,
  setLoading,
  locale,
  setState,
  setActiveStep,
}) {
  const [activePaymentStep, setActivePaymentStep] = useState("todo");
  const [appointmentUpdater, setAppointmentUpdater] = useState(false);
  const startRazorpay = (orderId) => {
    displayRazorpay(
      {
        ...defaultOptions,
        handler: function (response) {
          setLoading(true);
          setAppointmentUpdater((prev) => !prev);
        },
        order_id: orderId,
        prefill: {
          name: "Prakash",
          contact: +918299214866,
          email: "support@paperplanetech.co",
        },
        modal: {
          escape: false,
          confirm_close: true,
          ondismiss: () => {
            toast.warning(locale.paymentWarning);
            setActivePaymentStep("todo");
          },
        },
        config: {
          display: {
            language: paymentLocale,
          },
        },
      },
      setActivePaymentStep
    );
  };
  const handlePayment = (couponDetails) => {
    setLoading(true);
    loadRazorPay();
    customAxios
      .post(`/appointments/razorpay-order/${state?.appointmentId}`, {
        couponCode: couponDetails?.couponCode,
      })
      .then((res) => {
        if (res.data.success) {
          setState({ ...state, orderId: res.data.data.razorpayOrderId });
          setLoading(false);
          startRazorpay(res.data.data.razorpayOrderId);
        } else {
          setLoading(false);
          toast.error("Some error occured. Please try again later.");
        }
      })
      .catch((err) => {
        toast.error(locale.paymentError);
        setLoading(false);
      });
  };
  const getActivePaymentStep = (activePaymentStep) => {
    switch (activePaymentStep) {
      case "todo":
        return (
          <PaymentDetails
            state={state}
            locale={locale}
            setLoading={setLoading}
            handlePayment={handlePayment}
          />
        );
      case "error":
        return <PaymentError />;
      case "success":
        return (
          <PaymentSuccessful locale={locale} setActiveStep={setActiveStep} />
        );
      default:
        return (
          <Typography variant="h1" color="initial">
            Error
          </Typography>
        );
    }
  };
  useEffect(() => {
    if (state.appointmentId && state.orderId) {
      customAxios
        .post(`/appointments/check_payment_status/${state.appointmentId}`, {})
        .then((res) => {
          if (res.data.data) {
            setLoading(false);

            customAxios
              .get(
                `/appointments/send-appointment-message/${state.appointmentId}?language=${paymentLocale}`
              )
              .then((res) => {
                if (res.data.success) {
                } else {
                }
              })
              .catch((err) => console.log("error"));
            setActivePaymentStep("success");
          } else {
            toast.error(locale.paymentRetry);
            setLoading(false);

            setActivePaymentStep("todo");
          }
        })
        .catch((err) => {
          toast.error(locale.paymentRetry);
          setLoading(false);
        });
    }
    //eslint-disable-next-line
  }, [appointmentUpdater]);

  return (
    <Grid container item xs={12} justifyContent="center">
      {getActivePaymentStep(activePaymentStep)}
    </Grid>
  );
}

export default PaymentPage;
