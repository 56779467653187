import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
  Chip,
  MenuItem,
} from "@material-ui/core";
import 'date-fns';

import { DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import moment from "moment"
import DateFnsUtils from "@date-io/date-fns" // choose your lib


import mixpanel from "mixpanel-browser";
import { toast } from "react-toastify";
import customAxios from "../../axios";

function Assessment({ fullData, width, setActiveStep, locale, setLoading }) {
  const handleClose = () => {
    setDialogOpen(false);
  };
  const [documents, setDocuments] = useState([]);

  const [countryCode, setCountryCode] = useState("+970")

  const LabReportRef = useRef();
  const PrescriptionsRef = useRef();
  const OthersRef = useRef();

  const getTextField = (type, ele, idx) => {
    console.log("type", type);
    switch (type) {
      case "text":
        return (
          <Grid item xs={12}>
            <TextField
              fullWidth
              // placeholder="Your answer"
              multiline
              required={ele?.required}
              minRows={ele?.question === "ما هي شكواك مع الكثير من التفاصيل؟" ? 4 : 1}
              type="text"
              error={ele?.error}
              variant="outlined"
              onChange={(e) => {
                const temp = [...state];
                temp[idx]["answer"] = e.target.value;
                setState([...temp]);
              }}
              color="primary"
            ></TextField>
          </Grid>
        );
      case "date":
        return (
          <Grid item xs={12}>
            {/*  */}
            <TextField
              fullWidth
              variant="outlined"
              
              type="date"
              onChange={(e) => {
                const temp = [...state];
                temp[idx]["answer"] = e.target.value;
                setState([...temp]);
              }}
              color="primary"
              // inputProps={{
              //   pattern: '\\d{4}-\\d{2}-\\d{2}', // Set the pattern to enforce the format
              // }}
            ></TextField>
          </Grid>
        );
      case "age":
        return (
          
          <Grid item xs={12}>
             <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {/* <DatePicker
                autoOk
                // disableToolbar
                variant="outlined"
                format="dd/MM/yyyy"
                // label="Select Date"
                value={null} // Provide the selected date here if needed
                onChange={(e) => {
                  console.log(e, '98979897check')
                }}
                TextFieldComponent={(props) => (
                  <TextField
                    {...props}
                    helperText=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              /> */}
              <KeyboardDatePicker
                autoOk
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                error={ele?.error}
                // id="date-picker-inline"
                // label="Date picker inline"
                value={state?.[idx]?.['answer'] ?? ""}
                onChange={(e) => {
                  if(e){
                    const temp = [...state];
                    temp[idx]["answer"] = moment.utc(e).format("YYYY-MM-DD");
                    setState([...temp]);
                  }
                }}
                // KeyboardButtonProps={{
                //   'aria-label': 'change date',
                // }}
                TextFieldComponent={(props) => (
                  <TextField
                    {...props}
                    fullWidth
                    helperText=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </MuiPickersUtilsProvider>
            {/* <TextField
              fullWidth
              variant="outlined"
              type="date"
              onChange={(e) => {
                const temp = [...state];
                temp[idx]["answer"] = e.target.value;
                setState([...temp]);
              }}
              color="primary"
            ></TextField> */}
          </Grid>
        );
      case "phone": {
        return (
          <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
            <TextField
              select
              style={{width: "7%", minWidth: "100px", marginRight: "5px"}}
              variant="outlined"
              value={countryCode}
              onChange={(e) => {
                setCountryCode(e?.target?.value);
              }}
            >
              <MenuItem value="+1">+1</MenuItem>
              <MenuItem value="+20">+20</MenuItem>
              <MenuItem value="+61">+61</MenuItem>
              <MenuItem value="+91">+91</MenuItem>
              <MenuItem value="+962">+962</MenuItem>
              <MenuItem value="+970">+970</MenuItem>
              <MenuItem value="+972">+972</MenuItem>
            </TextField>
            <TextField
              fullWidth
              // placeholder="Your answer"
              // multiline
              type="text"
              variant="outlined"
              error={ele?.error}

              onChange={(e) => {
                const temp = [...state];
                temp[idx]["answer"] = e?.target?.value ? countryCode+e?.target?.value : '';
                setState([...temp]);
              }}
              color="primary"
            ></TextField>
          </Grid>
        );
      }
      case "checkbox":
        return (
          <Grid item xs={12}>
            {ele.options.map((option, optionIndex) => (
              <FormControlLabel
                label={option}
                control={
                  <Checkbox
                    color="primary"
                    onChange={(e) => {
                      const temp = [...state];

                      let answers = temp[idx]?.answer
                        ? JSON.parse(temp[idx]?.answer)
                        : [];
                      console.log("answers", answers);
                      if (e.target.checked) {
                        if (answers) answers.push(optionIndex);
                        else answers = [optionIndex];
                      } else {
                        answers = answers.filter(
                          (f) => Number(f) !== optionIndex
                        );
                      }

                      temp[idx].answer = JSON.stringify(answers);
                      setState([...temp]);
                    }}
                  />
                }
              />
            ))}
          </Grid>
        );
      case "radio":
        return (
          <Grid item xs={12}>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              onChange={(e) => {
                const temp = [...state];
                temp[idx]["answer"] = e.target.value;
                setState([...temp]);
              }}
            >
              {ele?.options?.map((option, optionIndex) => (
                <FormControlLabel
                  label={option}
                  control={<Radio color="primary" />}
                  value={option}
                />
              ))}
            </RadioGroup>
          </Grid>
        );
      default:
        return (
          <Grid item xs={12}>
            <TextField
              fullWidth
              // placeholder="Your answer"
              multiline
              type="text"
              variant="outlined"
              error={ele?.error}

              onChange={(e) => {
                const temp = [...state];
                temp[idx]["answer"] = e.target.value;
                setState([...temp]);
              }}
              color="primary"
            ></TextField>
          </Grid>
        );
    }
  };
  console.log("docs", documents);
  const uploadFiles = async (files, type, color) => {
    const tempArr = [...documents];
    if (files) {
      for (const file of files) {
        let reader = new FileReader();

        reader.onload = function (e) {
          tempArr.push({
            color: color,
            file: e.target.result,
            name: file.name,

            type: type,
            extension: file.type,
          });

          setDocuments([...tempArr]);
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const handleChange = (value, type, idx) => {};
  const [state, setState] = useState([]);
  console.log("sttae", state);
  useEffect(() => {
    setLoading(true);
    if (fullData?.docId)
      customAxios
        .get(`/doctor/get-custom-form/${fullData?.docId}`)
        .then((res) => {
          setState([...res.data.data]);
          console.log(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    //eslint-disable-next-line
  }, [fullData?.docId]);
  console.log("state", state);
  const handleSubmit = (state, documents) => {

    let error = false

    let tempState = state.map((v) => {
      if(v?.required && (!v?.answer || v?.answer === "" || !v?.answer?.length)){
        error = true;
        return {...v, error: true}
      } else 
      return {...v, error: false}
    })

    if(!error){
      customAxios
        .put(
          `/patients/update-characteristics/${fullData?.patientId}?doc_id=${fullData?.docId}`,
          {
            data: [...state],
          }
        )
        .then((res) => {
          if (res.data.success) {
            toast.success("Submitted Successfully.");          
            setLoading(false);
            customAxios
              .post(`/appointments/upload-documents/${fullData.appointmentId}`, {
                documents: documents,
              })
              .then((res) => {})
            mixpanel.track("Submitted Assessment");
            setActiveStep((prev) => prev + 1);
          } else {
            toast.error("Some error occured.");
          }
        });
    } else {
      setState([...tempState]);
      toast.error("يرجى ملء جميع التفاصيل")
    }

  };
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Grid container item spacing={3}>
      {/* <Grid item xs={12}>
        <Typography variant="h1">{locale.files}:</Typography>
        <Typography variant="h3" color="secondary">
          {locale.filesSubText}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
        <Button
          style={{ color: "#6C7BFF", borderColor: "#6C7BFF" }}
          fullWidth
          variant="outlined"
          onClick={() => LabReportRef.current.click()}
        >
          {locale.addLabReports}
        </Button>
      </Grid>

      <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
        <Button
          style={{ color: "#6dbaa1", borderColor: "#6dbaa1" }}
          color="primary"
          fullWidth
          variant="outlined"
          onClick={() => PrescriptionsRef.current.click()}
        >
          {locale.addPrescription}
        </Button>
      </Grid>
      <input
        type="file"
        multiple
        hidden
        accept="image/*,.pdf"
        ref={PrescriptionsRef}
        onChange={(e) => uploadFiles(e.target.files, "Prescription", "#6dbaa1")}
      ></input>
      <input
        type="file"
        multiple
        hidden
        accept="image/*,.pdf"
        ref={OthersRef}
        onChange={(e) => uploadFiles(e.target.files, "Other", "#Bcb8ef")}
      ></input>
      <input
        type="file"
        multiple
        hidden
        accept="image/*,.pdf"
        ref={LabReportRef}
        onChange={(e) => uploadFiles(e.target.files, "Lab Report", "#6C7BFF")}
      ></input>

      <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
        <Button
          fullWidth
          style={{ color: "#Bcb8ef", borderColor: "#Bcb8ef" }}
          variant="outlined"
          onClick={() => OthersRef.current.click()}
        >
          {locale.addOthers}
        </Button>
      </Grid>
      <Grid item container xs={12} spacing={1} style={{ marginTop: "1rem" }}>
        {documents.map((file, idx) => (
          <Grid item>
            <Chip
              variant="outlined"
              style={{ color: file.color, borderColor: file.color }}
              onDelete={() => {
                const temp = [...documents];

                temp.splice(idx, 1);
                setDocuments([...temp]);
              }}
              label={file.name}
            ></Chip>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} style={{ margin: "1rem 0" }}>
        <Divider />
      </Grid>*/}
      <Grid item xs={12} style={{textAlign: "center", marginBottom: "10px"}}>
        <Typography variant="h2" style={{fontWeight: "bold"}}>{locale.form}</Typography>
        <Typography variant="subtitle1" color="secondary">
          {locale.formSubText}
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        {state?.map((ele, idx) => (
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <Typography variant="h4">{ele.question}</Typography>
            </Grid>

            {getTextField(ele.type, ele, idx)}
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}></Grid>
        {fullData.newPatient && (
          <Grid item>
            <Dialog
              open={dialogOpen}
              onClose={handleClose}
              style={{ color: "ButtonShadow" }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" color="primary">
                Note
              </DialogTitle>
              <Divider></Divider>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography>
                    Please fill this form correctly to help your doctor diagnose
                    you better.
                  </Typography>
                  <Typography>
                    You need to fill this form only once. It will be autofilled,
                    the next time you book an appointment with us.
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <Divider></Divider>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="primary"
                  autoFocus
                  variant="contained"
                >
                  Okay
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        )}

        <Grid
          container
          item
          justify={
            width !== "sm" && width !== "md" && width !== "xs"
              ? "flex-end"
              : "center"
          }
        >
          <Grid item>
            <Button
              onClick={() => {
                handleSubmit(state, documents);
              }}
              color="primary"
              fullWidth
              variant="contained"
              size="large"
            >
              {locale.submit}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Assessment;
