export const displayRazorpay = (options, setActiveStep) => {
  const rzp1 = new window.Razorpay(options);
  rzp1.on("payment.failed", function (response) {
    setActiveStep("error");
  });
  rzp1.open();
};

export const loadRazorPay = () => {
  const script = document.createElement("script");
  script.src = "https://checkout.razorpay.com/v1/checkout.js";
  document.body.appendChild(script);

  script.onload = () => {};
};

export const defaultOptions = {
  key: "rzp_live_GO71P1BscARhu6",
  amount: "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
  currency: "INR",
  name: "Paperplane Communications",
  description: "Consultation Transaction",
  image: "",
  //This is a sample Order ID. Pass the `id` obtained in the response of Step 1

  theme: {
    color: "#5E72E4",
  },
  redirect: false,
  timeout: 600,
};
