import {
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  withWidth,
} from "@material-ui/core";
import React from "react";
import Animation from "../../components/Animation";
import submitAnimation from "./../../assets/animation/submit.json";
function Submitted({ width }) {
  return (
    <Paper
      elevation={width === "xs" || width === "sm" || width === "md" ? 2 : 0}
    >
      <Grid container justify="center" spacing={3} alignItems="center">
        <Grid
          container
          item
          xs={12}
          justify="center"
          alignItems="center"
          direction="column"
        >
          <Grid item xs={12}>
            <Animation animationData={submitAnimation} />
          </Grid>
          <Grid item>
            <Typography variant="h4" align="center">
              The Patient Assessment has been taken and will reveiwed by doctor. <br />
              You can now close this screen.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item>
          <Button
            color="primary"
            onClick={() => window.close()}
            variant="contained"
          >
            Close window
          </Button>
        </Grid>{" "}
        <Grid item xs={12}></Grid>
      </Grid>
    </Paper>
  );
}

export default withWidth()(Submitted);
